import { useMountedEffect } from "@/common/hooks/use-mounted-effect";
import { LoadingOverlay } from "@mantine/core";
import { useDidUpdate } from "@mantine/hooks";
import { RefObject, useEffect, useState } from "react";

type Props = {
	imgRef: RefObject<HTMLImageElement>;
	src?: string | null;
	loading?: boolean;
};

export const ImageLoadingOverlay = ({ imgRef, src, loading }: Props) => {
	const [isLoading, setIsLoading] = useState(!!loading);

	const handleImageLoaded = () => {
		setIsLoading(false);
	};

	useEffect(() => {
		if (imgRef.current) {
			imgRef.current.addEventListener("load", handleImageLoaded);
			imgRef.current.addEventListener("error", handleImageLoaded);
		}

		return () => {
			if (imgRef.current) {
				imgRef.current.removeEventListener("load", handleImageLoaded);
				imgRef.current.removeEventListener("error", handleImageLoaded);
			}
		};
	}, [src]);

	useMountedEffect(() => {
		if (!imgRef.current?.complete && !!src) {
			setIsLoading(true);
		}
	}, [src]);

	useDidUpdate(() => {
		if (imgRef.current?.complete === false && !loading) {
			setIsLoading(true);
		}
	}, [src]);

	useDidUpdate(() => {
		if (loading !== undefined) {
			setIsLoading(loading);
		}
	}, [loading]);

	return (
		<LoadingOverlay
			visible={isLoading}
			overlayBlur={4}
			zIndex={10}
			loaderProps={{
				color: "blue",
			}}
		/>
	);
};
