import type { ReactNode } from "react";
import { createContext, useCallback, useContext, useMemo } from "react";
import { useDebouncedCallback } from "use-debounce";

export type FieldValue = unknown;

export interface UpdateFunction<FormValues = Record<string, FieldValue>> {
	(
		updates: {
			[Key in keyof FormValues]: FormValues[Key];
		},
		options?: { immediate?: boolean },
	): unknown;
}

type ContextProperties<FormValues = Record<string, FieldValue>> = {
	updateDebouncedField: UpdateFunction<FormValues>;
	delay: number;
	updateImmediately: () => void;
};

export type AutosaveProviderProps<
	FormValues extends Record<string, FieldValue> = Record<string, FieldValue>,
> = {
	/**
	 * Async function that makes a request or whatever
	 * to update the form in backend
	 */
	onUpdate: UpdateFunction<FormValues>;
	/**
	 * How long should we wait until we call `onChangeDebouncedValue`
	 */
	delay?: number;
	children:
		| ReactNode
		| ((properties: ContextProperties<FormValues>) => ReactNode);
};

const AutoSaveContext = createContext<ContextProperties | undefined>(undefined);

export const AutoSaveProvider = <
	FormValues extends Record<string, FieldValue>,
>({ children, onUpdate, delay = 1000 }: AutosaveProviderProps<FormValues>) => {
	/**
	 * `debouncedUpdate` is a function that is called once in a `delay` time.
	 * `callPending` is to immediately call `debouncedUpdate` and cancel waiting.
	 *
	 * `callPending` is called `onBlur` in `FormikTextF` because changing two or more fields
	 * in less then a `delay` time would result in just one change instead of change for all fields.
	 * This way we can call `debouncedUpdate` immediately when it losts its focus and we have a clear
	 * `debouncedUpdate` pool.
	 *
	 * Last step is to set `isSaving` to false to indicate that form has been saved.
	 */
	const debouncedUpdate = useDebouncedCallback<UpdateFunction<FormValues>>(
		async (updates) => {
			await onUpdate(updates);
		},
		delay,
	);

	/**
	 * This is called on every call `onChange` function in `FormikTextF`.
	 * It sets `isSaving` to true to indicate that form is not yet saved
	 * and then it calls debounced function that makes some async action.
	 */
	const updateDebouncedField: UpdateFunction<FormValues> = useCallback(
		(updates, options) => {
			debouncedUpdate(updates);

			if (options?.immediate) {
				debouncedUpdate.flush();
			}
		},
		[debouncedUpdate],
	);

	const value = useMemo(
		() => ({
			updateDebouncedField,
			delay,
			updateImmediately: debouncedUpdate.flush,
		}),
		[delay, updateDebouncedField, debouncedUpdate.flush],
	);

	return (
		// @ts-ignore
		<AutoSaveContext.Provider value={value}>
			{typeof children === "function" ? children(value) : children}
		</AutoSaveContext.Provider>
	);
};

/**
 * @param unsafe Ignore thrown error if context AutoSaveContext is not available
 */
export const useAutoSave = <
	FormValues extends Record<string, FieldValue> = Record<string, FieldValue>,
>(
	unsafe = false,
): ContextProperties<FormValues> => {
	const context = useContext<ContextProperties<FormValues>>(
		AutoSaveContext as unknown as React.Context<ContextProperties<FormValues>>,
	);

	if (!context && !unsafe) {
		throw new Error("useAutoSave must be used within AutosaveProvider");
	}

	return context;
};
