import type { ContainerProps } from "@mantine/core";
import { Container } from "@mantine/core";
import { useStyles } from "./page-container.styles";

export const PageContainer = ({
  children,
  size = "xl",
  ...rest
}: ContainerProps) => {
  const { classes, cx } = useStyles();

  return (
    <Container
      size={size}
      {...rest}
      className={cx(classes.container, rest.className)}
    >
      {children}
    </Container>
  );
};
