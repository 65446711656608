import { joinStringsWithFallback } from "@/libs/common/string-utils";
import type { TextProps } from "@mantine/core";
import { Text, Tooltip } from "@mantine/core";
import { useMergedRef } from "@mantine/hooks";
import { forwardRef, useEffect, useRef, useState } from "react";

export type CategoriesTextProps = TextProps & {
	mainCategory?: string | null;
	subcategoryOne?: string | null;
	subcategoryTwo?: string | null;
};

export const CategoriesText = forwardRef<HTMLDivElement, CategoriesTextProps>(
	({ mainCategory, subcategoryOne, subcategoryTwo, ...rest }, ref) => {
		const containerRef = useRef<HTMLDivElement | null>(null);
		const mergedRef = useMergedRef(ref, containerRef);
		const [shouldDisplayTooltip, setShouldDisplayTooltip] = useState(false);

		const categoriesText = joinStringsWithFallback(
			[mainCategory, subcategoryOne, subcategoryTwo],
			"(No categories provided)",
		);

		useEffect(() => {
			const containerRefCurr = containerRef.current;
			if (
				containerRefCurr &&
				containerRefCurr.offsetWidth < containerRefCurr.scrollWidth
			) {
				setShouldDisplayTooltip(true);
			}
		}, [containerRef]);

		const textElement = (
			<Text ref={mergedRef} size="sm" color="dimmed" truncate {...rest}>
				{categoriesText}
			</Text>
		);

		if (shouldDisplayTooltip) {
			return (
				<Tooltip label={categoriesText} sx={{ cursor: "pointer" }}>
					{textElement}
				</Tooltip>
			);
		}

		return textElement;
	},
);

CategoriesText.displayName = "forwardRef(CategoriesText)";
