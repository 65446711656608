import { ImageLoadingOverlay } from "@/ui/image-loader-overlay";
import type { BoxProps, ImageProps } from "@mantine/core";
import {
	ActionIcon,
	Box,
	Flex,
	Group,
	LoadingOverlay,
	Menu,
	Paper,
	Text,
	ThemeIcon,
	createStyles,
} from "@mantine/core";
import { IconDotsVertical, IconPlus } from "@tabler/icons-react";
import type {
	CSSProperties,
	ChangeEvent,
	ComponentPropsWithoutRef,
} from "react";
import { useRef } from "react";
import { EnlargeableImage } from "../enlargeable-image";

type Props = BoxProps & {
	onChange?: (
		e: ChangeEvent<HTMLInputElement> & { target: { files: FileList } },
	) => void;
	containerClassname?: string;
	imageClassName?: string;
	error?: string;
	inputProps?: ComponentPropsWithoutRef<"input">;
	isLoading?: boolean;
	name?: string;
	description?: string;
	thumbnail?: string | null;
	height?: number | string;
	title?: string;
	isReadOnly?: boolean;
	imageProps?: ImageProps;
	withLoader?: boolean;
};

type StylesProperties = {
	height?: CSSProperties["height"];
	hasError?: boolean;
	hasThumbnail?: boolean;
};

const useStyles = createStyles(
	(theme, { height, hasError }: StylesProperties) => ({
		container: {
			position: "relative",
			minHeight: 250,
			height: height ?? 250,
		},

		wrapper: {
			overflow: "hidden",
			position: "relative",
			height: "100%",
			display: "flex",
			borderColor: hasError
				? theme.colors.red[theme.fn.primaryShade()]
				: undefined,
			borderStyle: hasError ? "dashed" : undefined,
		},

		figure: {
			height: "100%",
		},

		imageWrapper: {
			height: "100%",
		},
	}),
);

export const ImageUploadCard = ({
	onChange,
	error,
	inputProps,
	isLoading,
	name,
	thumbnail,
	height,
	title,
	isReadOnly,
	imageProps,
	withLoader,
	description = "(Recommended size 2560x1440px, up to 5MB)",
	...rest
}: Props) => {
	const { classes } = useStyles({ height });
	const inputRef = useRef<HTMLInputElement>(null);
	const imageRef = useRef<HTMLImageElement>(null);

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files || e.target.files.length === 0) return;

		onChange?.(
			e as ChangeEvent<HTMLInputElement> & { target: { files: FileList } },
		);
	};

	return (
		<Box className={classes.container} {...rest}>
			<Paper withBorder className={classes.wrapper}>
				{!isReadOnly && withLoader ? (
					<ImageLoadingOverlay
						imgRef={imageRef}
						loading={!!isLoading}
						src={thumbnail}
					/>
				) : (
					<LoadingOverlay
						visible={!!isLoading}
						overlayBlur={4}
						zIndex={10}
						color="blue"
					/>
				)}
				<Box
					component="input"
					type="file"
					hidden
					id={name}
					name={name}
					onChange={handleInputChange}
					disabled={isReadOnly}
					ref={inputRef}
					{...inputProps}
				/>
				{thumbnail ? (
					<>
						<EnlargeableImage
							src={thumbnail}
							withPlaceholder
							classNames={{
								figure: classes.figure,
								imageWrapper: classes.imageWrapper,
							}}
							height="100%"
							ref={imageRef}
							{...imageProps}
						/>

						{!isReadOnly && (
							<Menu>
								<Menu.Target>
									<ActionIcon
										pos="absolute"
										top={16}
										right={16}
										size="lg"
										variant="light"
									>
										<IconDotsVertical />
									</ActionIcon>
								</Menu.Target>
								<Menu.Dropdown>
									<label htmlFor={name}>
										<Menu.Item component="div">Reupload</Menu.Item>
									</label>
								</Menu.Dropdown>
							</Menu>
						)}
					</>
				) : (
					<Box
						component="label"
						htmlFor={name}
						w="100%"
						display="flex"
						sx={{
							alignItems: "center",
							justifyContent: "center",
							cursor: "pointer",
						}}
					>
						<Box p="xl" ta="center">
							<Flex
								direction="column"
								align="center"
								sx={{
									color: isReadOnly ? "gray.4" : "primary",
									"&:hover": {
										color: isReadOnly ? "gray.4" : "black",
									},
								}}
							>
								<Flex align="center" sx={{ cursor: "pointer" }}>
									<Group spacing="xs">
										<ThemeIcon
											variant="outline"
											color={error ? "red" : undefined}
											size="sm"
										>
											<IconPlus size={16} />
										</ThemeIcon>
										<Text size="sm" color={error ? "red" : "primary"}>
											{title || "Upload image"}
										</Text>
									</Group>
								</Flex>
								{description && (
									<Text size="xs" mt="xs" color={error ? "red" : "primary"}>
										{description}
									</Text>
								)}
							</Flex>
						</Box>
					</Box>
				)}
			</Paper>
		</Box>
	);
};
