import { useAutoSave } from "@/contexts/auto-save";
import type { TextInputProps } from "@mantine/core";
import { TextInput } from "@mantine/core";
import type { ChangeEvent, FocusEvent } from "react";
import { forwardRef } from "react";

export const AutosaveTextInput = forwardRef<HTMLInputElement, TextInputProps>(
	(props, ref) => {
		const { updateDebouncedField, updateImmediately } = useAutoSave();

		const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
			props.onChange?.(event);
			updateDebouncedField({ [event.target.name]: event.target.value });
		};

		const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
			props.onBlur?.(event);
			updateImmediately();
		};
		return (
			<TextInput
				{...props}
				onChange={handleChange}
				onBlur={handleBlur}
				ref={ref}
			/>
		);
	},
);

AutosaveTextInput.displayName = "AutosaveTextInput";
