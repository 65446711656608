import {
	ActionIcon,
	Button,
	Flex,
	Group,
	Input,
	Textarea,
	createStyles,
} from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";

export type ReviewCommentFieldProps = {
	defaultValue?: string | null;
	readOnly?: boolean;
	onEditComment?: (text: string) => void;
	onClearComment?: () => void;
};

const useStyles = createStyles((theme, vars: { readOnly?: boolean }) => ({
	inputInput: {
		height: "auto",
		padding: theme.spacing.md,
		...(vars.readOnly && {
			"&:focus, &:focus-within": {
				borderColor:
					theme.colorScheme === "dark"
						? theme.colors.dark[4]
						: theme.colors.gray[4],
			},
			pointerEvents: "none",
		}),
	},

	textarea: {
		flex: 1,
	},

	textareaInput: {
		padding: 0,
	},
}));

export const ReviewCommentField = ({
	defaultValue,
	readOnly,
	onEditComment,
	onClearComment,
}: ReviewCommentFieldProps) => {
	const { classes } = useStyles({ readOnly });
	const [editComment, setEditComment] = useState<boolean>(false);
	const inputRef = useRef<HTMLTextAreaElement>(null);

	const handleFocus = () => {
		if (!readOnly && !editComment) {
			setEditComment(true);
		}
	};

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.value = defaultValue ?? "";
		}
	}, [defaultValue]);

	return (
		<Input component="div" classNames={{ input: classes.inputInput }}>
			<Flex justify="space-between">
				<Textarea
					variant="unstyled"
					readOnly={!editComment}
					className={classes.textarea}
					classNames={{
						input: classes.textareaInput,
					}}
					defaultValue={defaultValue ?? undefined}
					minRows={4}
					placeholder="Comment text..."
					onFocus={handleFocus}
					ref={inputRef}
				/>
				{!readOnly && !editComment && (
					<Group sx={{ alignSelf: "flex-start" }}>
						<ActionIcon
							onClick={() => {
								setEditComment((prev) => !prev);
							}}
						>
							<IconEdit />
						</ActionIcon>
						<ActionIcon
							onClick={() => {
								inputRef.current!.value = "";
								onClearComment?.();
							}}
						>
							<IconTrash />
						</ActionIcon>
					</Group>
				)}
			</Flex>
			{!readOnly && editComment && (
				<Group mt="md">
					<Button
						onClick={() => {
							onEditComment!(inputRef.current!.value);
							setEditComment(false);
						}}
					>
						Add comment
					</Button>
					<Button
						variant="default"
						onClick={() => {
							setEditComment(false);
						}}
					>
						Cancel
					</Button>
				</Group>
			)}
		</Input>
	);
};
