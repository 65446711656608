import type { MantineSize, Sx } from "@mantine/core";
import { ActionIcon, TextInput } from "@mantine/core";
import { useMergedRef } from "@mantine/hooks";
import { IconSearch, IconX } from "@tabler/icons-react";
import React from "react";
import type { ChangeEvent, MouseEventHandler } from "react";
import { forwardRef, useRef } from "react";

export type SearchInputProps = {
	placeholder?: string;
	disabled?: boolean;
	value?: string;
	onChange?: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
	onClear: MouseEventHandler<HTMLButtonElement>;
	size?: MantineSize;
	defaultValue?: string;
	name?: string;
	sx?: Sx;
};

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
	({ onClear, ...rest }, ref) => {
		const inputRef = useRef<HTMLInputElement>(null);
		const mergedRef = useMergedRef(ref, inputRef);

		const handleClear = (event: React.MouseEvent<HTMLButtonElement>) => {
			if (inputRef.current) {
				inputRef.current.value = "";
			}

			onClear(event);
		};

		return (
			<TextInput
				{...rest}
				ref={mergedRef}
				icon={<IconSearch size={16} />}
				rightSection={
					<ActionIcon
						onClick={handleClear}
						size={rest.size}
						data-testid="search-input-clear-button"
					>
						<IconX size={16} />
					</ActionIcon>
				}
			/>
		);
	},
);
