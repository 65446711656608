import { Paper, Stack, Text } from "@mantine/core";
import { Link } from "@remix-run/react";

type Props = {
	image: string;
	description: string | null;
	title: string | null;
	className?: string;
} & ({ href: string } | { onClick: () => unknown } | {});

export const PortfolioReferenceCard = ({
	image,
	description,
	title,
	className,
	...rest
}: Props) => {
	const truncatedDescription =
		description && description.length > 400
			? `${description.slice(0, 400)}...`
			: description;

	return (
		<Paper
			withBorder
			// @ts-ignore
			component={"href" in rest ? Link : "div"}
			to={"href" in rest ? rest.href : undefined}
			role={"href" in rest ? "link" : "onClick" in rest ? "button" : "generic"}
			bg="white"
			sx={{ overflow: "hidden" }}
			display={"href" in rest ? "block" : undefined}
			// @ts-ignore
			onClick={"onClick" in rest ? rest.onClick : undefined}
		>
			<Paper
				h={192}
				bg="gray.2"
				style={{
					backgroundImage: `url("${image}?w=1536")`,
					backgroundSize: "cover",
					backgroundPosition: "center",
				}}
			/>
			<Stack py="md" px="xl">
				<Text weight={600} truncate>
					{title || "(Untitled portfolio)"}
				</Text>
				<Text
					sx={{
						overflowWrap: "break-word",
					}}
				>
					{truncatedDescription}{" "}
					<Text weight="bold" component="span">
						Read more
					</Text>
				</Text>
			</Stack>
		</Paper>
	);
};
