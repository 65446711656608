import { useAutoSave } from "@/contexts/auto-save";
import type { TextareaProps } from "@mantine/core";
import { Textarea } from "@mantine/core";
import type { ChangeEvent, FocusEvent } from "react";
import { forwardRef } from "react";
import invariant from "tiny-invariant";

export const AutosaveTextarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
	(props, ref) => {
		const { updateDebouncedField, updateImmediately } = useAutoSave();

		const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
			invariant(props.name, "AutosaveTextarea requires name prop");

			props.onChange?.(event);
			updateDebouncedField({ [props.name]: event.target.value });
		};

		const handleBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
			props.onBlur?.(event);
			updateImmediately();
		};

		return (
			<Textarea
				{...props}
				onChange={handleChange}
				onBlur={handleBlur}
				ref={ref}
			/>
		);
	},
);

AutosaveTextarea.displayName = "AutosaveTextarea";
