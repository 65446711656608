import { getNameInitials } from "@/libs/common/string-utils";
import { Avatar, Box, Paper, Text, createStyles } from "@mantine/core";
import { Link } from "@remix-run/react";
import type { MouseEvent } from "react";

export type DesignerCardBaseProps = {
	avatarUrl: string | null;
	fullName: string;
	title: string | null;
	onClick?: (e: MouseEvent<HTMLDivElement | HTMLAnchorElement>) => void;
	link?: string;
	leftIcon?: JSX.Element;
	rightIcon?: JSX.Element;
	children?: React.ReactNode;
};

const useStyles = createStyles(
	(_, props: { leftIcon?: JSX.Element; rightIcon?: JSX.Element }) => ({
		container: {
			flexDirection: "column",
		},

		avatarContainer: {
			display: "flex",
			justifyContent: "center",
			marginTop: props.leftIcon || props.rightIcon ? "60px" : "24px",
			marginBottom: "24px",
		},
	}),
);

export const DesignerCardBase = ({
	avatarUrl,
	fullName,
	title,
	onClick,
	link,
	leftIcon,
	rightIcon,
	children,
}: DesignerCardBaseProps) => {
	const { classes } = useStyles({ leftIcon, rightIcon });
	const component = link ? Link : "div";

	return (
		<Paper
			withBorder
			className={classes.container}
			p="xl"
			h="100%"
			display="flex"
			pos="relative"
			// @ts-ignore
			component={component}
			onClick={(e) => {
				onClick?.(e);
			}}
			to={link!}
		>
			{!!leftIcon && (
				<Box pos="absolute" top={24} left={24}>
					{leftIcon}
				</Box>
			)}
			{!!rightIcon && (
				<Box pos="absolute" top={24} right={24}>
					{rightIcon}
				</Box>
			)}

			<div className={classes.avatarContainer}>
				<Avatar
					radius={144}
					src={avatarUrl ? `${avatarUrl}?w=256` : null}
					size={144}
				>
					{getNameInitials(fullName)}
				</Avatar>
			</div>

			<Box px="xs" mb="xl">
				<Text weight={600} transform="uppercase" align="center" lineClamp={2}>
					{fullName}
				</Text>
				<Text size="sm" color="dimmed" align="center" lineClamp={3}>
					{title ?? "(No title)"}
				</Text>
			</Box>
			{children}
		</Paper>
	);
};
