import { Box, Text } from "@mantine/core";
import { IconMoodSad } from "@tabler/icons-react";

type Props = {
	customText?: string;
};

export const SomethingWentWrong = ({ customText }: Props) => (
	<Box p={{ base: "xl", md: 80 }} sx={{ textAlign: "center" }}>
		<IconMoodSad size={96} />

		<Text size='xl' weight={600}>
			Oops... something went wrong
		</Text>
		<Text mt='xs'>
			{customText ??
				"We apologize for the inconvenience, our team has been notified."}
		</Text>
	</Box>
);
