import { Flex, Paper, Text } from "@mantine/core";
import { Link } from "@remix-run/react";

type Props = {
	date: string | null;
	text: string;
	title: string;
	link: string;
};

export const ReleaseNoteItem = ({ date, text, title, link }: Props) => (
	<Paper withBorder component={Link} px="xl" py="md" to={link}>
		<Flex>
			<Text weight={600}>
				{title}
				{date ? ` / ${date}` : null}
			</Text>
		</Flex>
		<Text mt="xs">{text}</Text>
	</Paper>
);
